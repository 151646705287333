import { useState } from 'react';

import { useInterval } from '@tradener/lumen';

const useTimeAgo = (timestamp: string | null | undefined) => {
  const [_tick, setTick] = useState(0);

  useInterval(() => {
    setTick((prev) => prev + 1);
  }, 1000);

  if (!timestamp) return '';

  const diff = Date.now() - new Date(timestamp).getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `há ${days} ${days === 1 ? 'dia' : 'dias'}`;
  if (hours > 0) return `há ${hours} ${hours === 1 ? 'hora' : 'horas'}`;
  if (minutes > 0) return `há ${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`;

  return `há ${seconds} ${seconds === 1 ? 'segundo' : 'segundos'}`;
};

export default useTimeAgo;
