import useTimeAgo from '@elapsedTime';
import { CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Badge, WarningIcon, useBoolean } from '@tradener/lumen';

import client from '~/services/apollo';

const isStaging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';

export default function TimeAgo({ synchronizedAt, loadingDataTable }) {
  const timeAgo = useTimeAgo(synchronizedAt);
  const showReloadAlert = (() => {
    if (!timeAgo) return false;
    if (timeAgo.includes('segundo')) return false;
    if (timeAgo.includes('hora') || timeAgo.includes('dia')) return true;
    const minutes = parseInt(timeAgo.match(/\d+/)?.[0] || '0');

    return minutes >= (isStaging ? 59 : 15);
  })();
  const [loading, { on, off }] = useBoolean();

  const onRefresh = async () => {
    try {
      on();

      await Promise.all([
        client.resetStore(),
        client.refetchQueries({
          include: ['portfolio'],
          optimistic: true,
        }),
      ]);
    } finally {
      off();
    }
  };

  return (
    <div>
      {showReloadAlert && !loadingDataTable ? (
        <Tooltip title={'Base desatualizada'}>
          <Badge bgColor="yellow.500">
            <Typography style={{ fontSize: '14px', color: 'white' }}>
              <WarningIcon style={{ marginRight: '5px', marginBottom: '3px' }} />
              {synchronizedAt && `Atualizado ${timeAgo} `}
              <button
                style={{ cursor: 'pointer', background: 'none', border: 'none', textDecoration: 'underline', padding: 0 }}
                onClick={() => {
                  if (!loading) onRefresh();
                }}
              >
                {loading ? (
                  <CircularProgress style={{ marginLeft: '5px', marginBottom: '-5px' }} size={20} color="inherit" />
                ) : (
                  'Recarregar'
                )}
              </button>
            </Typography>
          </Badge>
        </Tooltip>
      ) : !loadingDataTable ? (
        <Tooltip title={(synchronizedAt && `Atualizado ${timeAgo}`) || ''}>
          <Typography color="secondary" style={{ fontSize: '14px' }}>
            {synchronizedAt && `Atualizado ${timeAgo}`}
          </Typography>
        </Tooltip>
      ) : (
        <Skeleton width={225} height={30} />
      )}
    </div>
  );
}
