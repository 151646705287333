import { useRef } from 'react';

import { useTheme } from '@material-ui/core';
import { Flex } from '@tradener/lumen';

import Container from '~/theme/layout/Container';
import Filter from '~/theme/layout/Filter';

import Badges from '../Badges';
import SimulationSubheader, { SimulationName } from '../Simulation/SimulationSubheader';
import SimulationBalance from '../Simulation/SimulationTable/SimulationBalance';
import SimulationDRE from '../Simulation/SimulationTable/SimulationDRE';
import SimulationExposure from '../Simulation/SimulationTable/SimulationExposure';
import { withSimulationTable, useSimulationTable } from '../Simulation/SimulationTable/withSimulationTable';
import useSimulation from '../useSimulation';
import TimeAgo from './timeAgo';

const isStaging = process.env.REACT_APP_ROLLBAR_ENV === 'staging';

export const submarkets = {
  se: 'SE/CO',
  s: 'Sul',
  ne: 'Nordeste',
  n: 'Norte',
};

function PortfolioTable() {
  const { synchronizedAt } = useSimulation();
  const { zIndex } = useTheme();
  const badgesRef = useRef<HTMLDivElement>(null);
  const { loading: loadingDataTable } = useSimulationTable();

  return (
    <>
      <SimulationSubheader width="calc(100% - 20px)" zIndex={zIndex.appBar}>
        <SimulationName />
        <TimeAgo synchronizedAt={synchronizedAt} loadingDataTable={loadingDataTable} />
      </SimulationSubheader>
      <Badges ref={badgesRef} />
      <Flex
        direction="row"
        justify="space-between"
        position="sticky"
        align="flex-start"
        top="300px"
        maxHeight={
          isStaging
            ? `calc(100vh - 197px - ${badgesRef.current ? '48px' : '0px'})`
            : `calc(100vh - 160px - ${badgesRef.current ? '48px' : '0px'})`
        }
      >
        <Container display="grid" overflow="auto" gridAutoColumns="repeat(auto-fit, minmax(100vw, 1fr))">
          <SimulationBalance />
          <SimulationDRE />
          <SimulationExposure />
        </Container>
        <Filter />
      </Flex>
    </>
  );
}

export default withSimulationTable(PortfolioTable);
