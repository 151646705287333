import useTimeAgo from '@elapsedTime';
import { Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function TimeAgo({ synchronizedAt, loadingDataTable }) {
  const timeAgo = useTimeAgo(synchronizedAt);

  return (
    <div>
      {!loadingDataTable ? (
        <Tooltip title={(synchronizedAt && `Atualizado ${timeAgo}`) || ''}>
          <Typography color="secondary" style={{ fontSize: '14px' }}>
            {synchronizedAt && `Atualizado ${timeAgo}`}
          </Typography>
        </Tooltip>
      ) : (
        <Skeleton width={200} height={30} />
      )}
    </div>
  );
}
