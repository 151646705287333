import { useEffect } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { fastProvider } from '@tradener/lumen';
import { nanoid } from 'nanoid';

import CREATE_SIMULATION, {
  ResponseProps as CreateResponseProps,
  PayloadProps as CreatePayloadProps,
} from '@graphql/mutation/createSimulation';
import NEW_PORTFOLIO from '@graphql/query/newPortfolio';
import SIMULATION, { ResponseProps, PayloadProps } from '@graphql/query/simulation';

import usePortfolio, { getVariables } from '~/pages/Portfolio/usePortfolio';
import cache from '~/services/link/cache';
import DEFAULT_ACCOUNTS from '~/utils/getDefaultAccounts';
import { getCachedTenants } from '~/utils/getTenants';

import useSimulation from '../../useSimulation';
import useSimulationRules from '../../useSimulationRules';
import compose from './compose';

const [withSimulationTable, useSimulationTable] = fastProvider(() => {
  const portfolio = usePortfolio();
  const { simulationId, setSimulation, setFilter } = useSimulation();
  const { isPortfolio } = useSimulationRules();
  const query = isPortfolio ? NEW_PORTFOLIO : SIMULATION;
  const {
    expandedPeriods,
    hasGiro,
    periodType,
    closeDateLte,
    closeDateGte,
    accountingPortfolio,
    internalAccounts,
    tenantId,
    ...rest
  } = getVariables(portfolio);

  const [createSimulation] = useMutation<CreateResponseProps, CreatePayloadProps>(CREATE_SIMULATION, {
    variables: { input: { name: null } },
    onCompleted: ({ createSimulation }) => setFilter('simulationId', createSimulation.simulation.id),
  });

  const variables = {
    ...rest,
    ...(isPortfolio ? {} : { simulationId }),
    expandedPeriods,
    excludedContracts: Object.keys(portfolio.excludedContracts),
  };

  const { data, loading, ...response } = useQuery<ResponseProps, Partial<PayloadProps>>(query, {
    skip: !internalAccounts?.length,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    canonizeResults: false,
    variables: {
      ...variables,
      hasGiro,
      periodType,
      closeDateLte,
      closeDateGte,
      accountingPortfolio,
      internalAccounts,
    },
    onCompleted: (data) => {
      if (data?.simulation) {
        setSimulation(data?.simulation?.simulation, isPortfolio);
      }
    },
    onError: ({ message }) => {
      if (message === 'not_found') createSimulation();
    },
  });

  useEffect(
    () => () => {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'simulation' });
    },
    [],
  );

  // TODO - remove this when filter is migrated from tenantId to internalAccounts
  useEffect(() => {
    if (!internalAccounts?.length) {
      const prevTenants = getCachedTenants()
        ?.filter((tenant) => tenantId?.includes(tenant.id))
        ?.map(({ name }) => name);
      const relatedAccounts = DEFAULT_ACCOUNTS.filter(({ name }) => prevTenants?.includes(name))?.map(
        ({ accountId }) => accountId,
      );

      portfolio.setFilter('internalAccounts', relatedAccounts);
    }
  }, [internalAccounts?.length, portfolio, tenantId]);

  return {
    ...response,
    data,
    loading,
    suffix: nanoid(),
    simulationPeriods: loading ? compose() : data?.simulation?.simulationPeriods,
    simulationOperations: loading ? [] : data?.simulation?.simulationOperations,
    hideCceeAmount: loading ? [] : data?.simulation?.hideCceeAmount,
  };
});

export { withSimulationTable, useSimulationTable };
