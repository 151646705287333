import { gql } from '@apollo/client';

import { EnergyTypes } from '@graphql/query/contracts';
import { PayloadProps as PortfolioPayloadProps, PriceTypes } from '@graphql/query/portfolio';

import type { OperationTypes, SubmarketTypes } from './simulationContracts';

export interface BalanceSubmarketProps {
  totalAmount: number;
  quantityMwm: number;
  price: number;
}

export interface PurchaseProps {
  totalAmount: number;
  quantityMwm: number;
  price: number;
  n: BalanceSubmarketProps;
  ne: BalanceSubmarketProps;
  s: BalanceSubmarketProps;
  se: BalanceSubmarketProps;
}

export interface SaleProps {
  totalAmount: number;
  quantityMwm: number;
  price: number;
  n: BalanceSubmarketProps;
  ne: BalanceSubmarketProps;
  s: BalanceSubmarketProps;
  se: BalanceSubmarketProps;
}

export interface BalanceProps {
  balanceMwm: number;
  totalAmount: number;
  originalTotalAmount: number;
  purchase: PurchaseProps;
  sale: SaleProps;
}

export interface DreProps {
  cceeAmount: number;
  argentinaBalanceAmount: number;
  uruguaiBalanceAmount: number;
  walletBalanceAmount: number;
  predictedBalanceAmount: number;
  highBalanceAmount: number;
  lowBalanceAmount: number;
  dcideBalanceAmount: number;
  bbceBalanceAmount: number;
  specialBallast: number;
  notSpecialBallast: number;
  globalBallast: number;
  pvFactor: number;
  pvTotalAmount: number;
  submarketDecoupling?: number;
  modulation?: number;
  retailerCharges?: number;
  balanceEffect?: number;
}

export interface SimulationPldProps {
  price?: number;
  finalPrice?: number;
  edited: boolean;
}

export interface ExposureSubmarketProps {
  balanceMwm: number;
  pld: number;
  simulationPld: SimulationPldProps | null;
}

export interface ExposureProps {
  n: ExposureSubmarketProps;
  ne: ExposureSubmarketProps;
  s: ExposureSubmarketProps;
  se: ExposureSubmarketProps;
}

export interface SimulationPeriodsProps {
  period: string;
  balance: BalanceProps;
  dre: DreProps;
  exposure: ExposureProps;
}

export interface SimulationItemProps {
  competence: string;
  price: number;
  quantityMwm: number;
}

export interface SimulationOperationsProps {
  id: string;
  createdAt: string;
  energyType: EnergyTypes;
  operation: OperationTypes;
  submarket: SubmarketTypes;
  priceType: PriceTypes;
  updatedAt: string;
  simulationItems: SimulationItemProps[];
}

export interface UserProps {
  id: string;
  name: string;
}

export interface SimulationProps {
  id: string;
  name: string;
  conventionalUsageFactor?: number;
  incentivizedUsageFactor?: number;
  user: UserProps;
  createdAt: string;
  updatedAt: string;
  synchronizedAt: string;
}

export interface SimulationPvProps {
  annualInterestRate?: number;
  totalAmount?: number;
}

export interface SimulationTableProps {
  simulation: SimulationProps;
  simulationPeriods: SimulationPeriodsProps[];
  simulationOperations?: SimulationOperationsProps[];
  simulationPv?: SimulationPvProps;
  hideCceeAmount?: boolean;
}

export interface PayloadProps extends PortfolioPayloadProps {
  simulationId: string | undefined;
}

export interface ResponseProps {
  simulation?: SimulationTableProps;
}

const BALANCE_FRAGMENT = gql`
  fragment balanceSubmarket on BalanceSubmarket {
    totalAmount
    quantityMwm
    price
  }

  fragment balanceOperation on BalanceOperation {
    totalAmount
    quantityMwm
    price
    se {
      ...balanceSubmarket
    }
    s {
      ...balanceSubmarket
    }
    ne {
      ...balanceSubmarket
    }
    n {
      ...balanceSubmarket
    }
  }

  fragment exposureSubmarket on SimulationExposureSubmarket {
    balanceMwm
    pld
    simulationPld {
      price
      finalPrice
      edited
    }
  }
`;

const SIMULATION = gql`
  ${BALANCE_FRAGMENT}

  query simulation(
    $simulationId: ID
    $startYear: String!
    $endYear: String
    $energyType: [String!]
    $periodType: QuotePeriod
    $priceType: [QuotePrice!]
    $expandedPeriods: [String!]
    $excludedContracts: [ID!]
    $tenantId: [ID!]
    $internalAccounts: [ID!]
    $usageFactorByContract: Boolean
    $usageFactorByEnergyType: Boolean
    $usageFactor: Boolean
    $giro: String
    $hasGiro: Boolean
    $contractFlags: [String!]
    $accountingPortfolio: ContractPortfolio
    $closeDateLte: ISO8601Date
    $closeDateGte: ISO8601Date
    $accountTypes: [String!]
  ) {
    simulation(
      simulationId: $simulationId
      startYear: $startYear
      endYear: $endYear
      energyType: $energyType
      periodType: $periodType
      priceType: $priceType
      expandedPeriods: $expandedPeriods
      excludedContracts: $excludedContracts
      tenantId: $tenantId
      internalAccounts: $internalAccounts
      usageFactorByContract: $usageFactorByContract
      usageFactorByEnergyType: $usageFactorByEnergyType
      usageFactor: $usageFactor
      giro: $giro
      hasGiro: $hasGiro
      contractFlags: $contractFlags
      accountingPortfolio: $accountingPortfolio
      closeDateLte: $closeDateLte
      closeDateGte: $closeDateGte
      accountTypes: $accountTypes
    ) {
      simulation {
        id
        name
        conventionalUsageFactor
        incentivizedUsageFactor
        user {
          id
          name
        }
        createdAt
        updatedAt
        synchronizedAt
      }
      simulationPeriods {
        period
        balance {
          quantityMwm: balanceMwm
          totalAmount
          originalTotalAmount
          purchase {
            ...balanceOperation
          }
          sale {
            ...balanceOperation
          }
        }
        dre {
          cceeAmount
          predictedBalanceAmount
          argentinaBalanceAmount
          uruguaiBalanceAmount
          walletBalanceAmount
          highBalanceAmount
          lowBalanceAmount
          dcideBalanceAmount
          bbceBalanceAmount
          specialBallast
          notSpecialBallast
          globalBallast
          pvFactor
          pvTotalAmount
          submarketDecoupling
          modulation
          retailerCharges
          balanceEffect
        }
        exposure {
          n {
            ...exposureSubmarket
          }
          ne {
            ...exposureSubmarket
          }
          s {
            ...exposureSubmarket
          }
          se {
            ...exposureSubmarket
          }
        }
      }
      simulationOperations {
        id
        createdAt
        energyType
        operation
        submarket
        priceType
        updatedAt
        simulationItems {
          competence
          quantityMwm
          price
        }
      }
      simulationPv {
        annualInterestRate
        totalAmount
      }
      hideCceeAmount
    }
  }
`;

export { BALANCE_FRAGMENT };
export default SIMULATION;
